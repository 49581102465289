@import '../../styles/customMediaQueries.css';

.root {
  border: none;
  padding: 0;

  @media (--viewportMedium) {
    position: relative;
    width: 258px;
    min-height: 50px;
    margin: 0;
  }

  ul {
    border: none;
    box-shadow: var(--boxShadowLight);
  }
}

.input {
  width: 100%;
  height: 50px;
  padding-left: 56px;
  border: none;
  outline: none;

  border-radius: 12px;

  font-weight: 400;
}

.input:not(select) {
  color: var(--colorGrey300);
}

input.input,
select.input {
  box-shadow: var(--boxShadowLight);
  text-overflow: ellipsis;
  padding-right: 30px;

  &:hover {
    border: none;
    outline: none;
  }
}

input.input {
  color: var(--colorGrey700);

  &::placeholder {
    color: var(--colorGrey300);
  }
}

.icon {
  position: absolute;
  left: 26px;
  top: 50%;
  transform: translate(0, -50%);

  color: var(--marketplaceColor);
}
