.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: 14px;
  border-radius: none;
  box-shadow: none;

  .input {
    width: fit-content;
    accent-color: var(--marketplaceColor);
  }

  .label {
    padding: 0;

    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}
