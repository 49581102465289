@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  text-transform: uppercase;

  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  color: var(--colorGrey900);

  margin: 0;
}

.resultsCount {
  color: var(--marketplaceColor);
}

.sortyByWrapper {
  display: inline-flex;
  align-items: center;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
