@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.btn,
.btnSelected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;

  padding-left: 30px;
  margin-bottom: 16px;

  border-radius: 12px;
  background-color: var(--colorWhite);
  outline: none;
  border: none;
  box-shadow: var(--boxShadowLight);

  font-weight: 400;
  color: var(--colorGrey300);

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
  }

  &:disabled {
    border-color: var(--colorGrey100);
  }

  @media (--viewportMedium) {
    width: 258px;
    height: 50px;
  }
}

.btnSelected {
  color: var(--colorGrey700);
}
