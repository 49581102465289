@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.labelPlain {
  color: var(--colorGrey700);
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field,
.fieldPlainInput {
  composes: h4 from global;
  margin: 0;

  input {
    border: none;
    outline: none;
    box-shadow: var(--boxShadowLight);
  }
}

.fieldPlain {
  composes: h4 from global;
  margin: 0;

  border: none;

  position: relative;
}

.fieldPlainLabel {
  padding: 4px 0 4px 0;
}
