@import '../../../styles/customMediaQueries.css';

.sortByDesktop {
  @media (min-width: 1200px) {
    margin-left: auto;
  }
}

.sortByMenuLabel {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--colorDarkBlue);
  width: 170px;
  height: 40px;
  min-height: 40px;

  padding: 0 18px;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */

  line-height: 21px;
  letter-spacing: 0.2px;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border-radius: 12px;
  border: none;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowLight);

  svg {
    flex-shrink: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
    border-color: var(--colorGrey300);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    width: fit-content;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}
